import React, { Component } from "react";
import Experience from "./Experience";

const company = "1SRCE.com";
const jobTitle = "Founder, Lead Developer";
const dateFrom = "March 2023";
const dateTo = "Present";
const description = () => (`
•	Designed and developed a notification dissemination solution capturing browser data and optionally storing user-supplied contact information.
•	Leveraged Twilio for SMS notifications, Azure Functions for serverless compute, and Azure Blob Storage for secure data storage.
•	Co-founded the startup offering technical staffing assistance to companies digitizing operations in the Kansas City Metropolitan Area.
`);
const accomplishments = () => (`
Successfully launched the startup and secured a contract with a local company to fill 2 roles. Currently working on building a sales team to expand the business.
`);
const skills = () => (`
Twilio, Azure Functions, Azure Blob Storage, JavaScript, Node.js, React, HTML, CSS, Bootstrap, Git, GitHub
`);
class OneSRCEExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default OneSRCEExperience;